import React from 'react';
import {motion} from "framer-motion";
import Slider, {Settings} from "react-slick";
import {useSelector} from 'react-redux';

import './index.scss';


function EventSlider() {
    const values: SlideContent[] = useSelector((state: any) => state.slider.value);

    let settings: Settings = {
        dots: true,
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
        // variableWidth: true,
        infinite: true,
        autoplay: true,
        accessibility: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1
                }
            }
        ]
    }

    return (
        <Slider {...settings}>
            {
                values.map(slide => {
                    return (
                        <div key={slide.id} className="slide-item">
                            <motion.div initial={{opacity: .3}} whileInView={{opacity: 1}} transition={{duration: .8}} className="img-responsive">
                                <img src={slide.image} alt={slide.title} className="img-fluid"/>
                            </motion.div>
                            <div className="slide-footer d-flex flex-row justify-content-start mt-2">
                                {/*<a href={slide.viewmore.url} className="btn-view-more link">{slide.viewmore.title}</a>*/}
                                <div className="slide-title">
                                    <h6>{slide.description}</h6>
                                    <h3>{slide.title}</h3>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </Slider>
    );
}

export default EventSlider;
