import React from 'react';
import {motion} from "framer-motion";
import './index.scss';
import icon from "../../assets/images/icon.png";
import {Link} from "react-scroll";

function EventInfo(props: any) {

    return (
        <div id={"info"} className={"container-fluid event-info-container g-0"}>
            <div className={"container-title text-center my-3"}>
                <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} src={icon} alt={"SeaBank"} className={"event-icon"}/>
            </div>
            <div className={"text-container"}>
                <div className={"text-content"}>
                    <p>Bước qua giới hạn, những nữ doanh nhân giờ đây đang vững vàng dẫn dắt doanh nghiệp phát triển, đóng góp lớn vào sự tăng trưởng của nền kinh tế.</p>
                    <p>Định vị trở thành "điểm đến tin cậy cho nữ chủ doanh nghiệp", ngân hàng SeABank ra mắt câu lạc bộ SeAPower dành riêng cho những nữ doanh nhân trên hành trình nâng tầm vị thế. SeAPower mang sứ mệnh trở thành cộng đồng gắn kết những nữ lãnh đạo xuất sắc, hứa hẹn mang đến dịch vụ
                        chăm sóc toàn diện cho các nữ lãnh đạo, đồng thời cung cấp đa dạng gói chăm sóc năng lực doanh nghiệp. Trở thành hội viên của SeAPower để tạo bước tiên phong cho phát triển doanh nghiệp bền vững.</p>
                </div>
            </div>
            <motion.div initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .5, delay: 2}} className={"d-flex flex-row justify-content-center event-controller"}>
                <Link to={"contact"} rel={'SeaBank'} className={"btn btn-light btn-register"} spy={true} smooth={true}><span className={"text-gradient"}>ĐĂNG KÝ NGAY</span></Link>
            </motion.div>
        </div>
    );
}

export default EventInfo;
