import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import {configureStore} from "@reduxjs/toolkit";
import {Provider} from "react-redux";

import router from "./router";
import sliderSlice from "./stores/SliderSlice";

import '@popperjs/core/dist/esm/popper';
import 'bootstrap/dist/js/bootstrap.js';

import './index.scss';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const store = configureStore({
    reducer: {
        slider: sliderSlice,
    },
});


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

