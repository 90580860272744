import {createSlice} from "@reduxjs/toolkit";

let initState: SlideContent[] = [];

const sliderSlice = createSlice({
    name: 'slider',
    initialState: {
        value: initState
    }, reducers: {
        addSlide: (state, action) => {
            if (!action.payload) return;
            if (Array.isArray(action.payload)) {
                action.payload.forEach((item) => {
                    if (state.value.findIndex((slide) => slide.id === item.id) === -1) {
                        state.value.push(item);
                    }
                });
            } else {
                state.value.push(action.payload);
            }
        },
        removeSlide: (state, action) => {
            if (!action.payload) return;
            state.value = state.value.filter((item) => item.id !== action.payload);
        }
    }
});

export const {addSlide, removeSlide} = sliderSlice.actions;
export default sliderSlice.reducer;