import React from 'react';
import './index.scss';

function Error() {
    return (
        <div id="wrapper">
            <div className="header_bg">
                <div id="error">
                    <div className="error">
                        <div className="error-content">
                            <h3>Oops! Page not found</h3>
                            <h1><span>4</span><span>0</span><span>4</span></h1>
                        </div>
                        <h2>Page not found</h2>
                        <a href="/" className="btn-goto-home">Goto <span>Homepage</span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error;