import React, {useState} from 'react';
import './index.scss';
import {Link} from "react-scroll";

function NavMobile(props: any) {

    let [menu0, setMenu0] = useState(false);
    let [menu1, setMenu1] = useState(true);
    let [menu2, setMenu2] = useState(false);
    // let [menu3, setMenu3] = useState(false);

    let onMenuClick = (index: number) => (event: any) => {
        let menus = [false, false, false, false];
        menus[index] = true;
        setMenu0(menus[0]);
        setMenu1(menus[1]);
        setMenu2(menus[2]);
        // setMenu3(menus[3]);
    }

    let onMenuChange = () => (event: any) => {

    }

    return (
        <div {...props}>
            <div className={"nav"}>
                <input type="radio" id="item-1" checked={menu0} name="menu" onChange={onMenuChange()}/>
                <input type="radio" id="item-2" checked={menu1} name="menu" onChange={onMenuChange()}/>
                <input type="radio" id="item-3" checked={menu2} name="menu" onChange={onMenuChange()}/>
                {/*<input type="radio" id="item-4" checked={menu3} name="menu" onChange={onMenuChange()}/>*/}
                <div className={"nav-bg"}>
                    <div className={"nav-bg-left"}>
                        <div className={"bg"}></div>
                        <div className={"nav-bg-left-top"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640">
                                <g>
                                    <path d="m334.39,95.16c40.52,130.26,162.02,224.84,305.61,224.84v320s-640,0-640,0V0s76.74,0,183.37,0c106.63,0,141.67,65.1,151.02,95.16Z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className={"nav-bg-right"}>
                        <div className={"nav-bg-right-top"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 640">
                                <g>
                                    <path d="m305.61,95.16C265.1,225.42,143.59,320,0,320v320h640V0h-183.37c-106.63,0-141.67,65.1-151.02,95.16Z"/>
                                </g>
                            </svg>
                        </div>
                        <div className={"bg"}></div>
                    </div>
                </div>
                <ul>
                    <li className={"active"}>
                        <Link to={"benefit"}>
                            <label onClick={onMenuClick(0)}>
                                <div>
                                    <i className="fa-solid fa-ticket"></i>
                                </div>
                                <span>Quyền lợi</span>
                            </label>
                        </Link>
                    </li>
                    <li>
                        <Link to={"slider"}>
                            <label onClick={onMenuClick(1)}>
                                <div>
                                    <i className="fa-solid fa-stars"></i>
                                </div>
                                <span>Sự kiện</span>
                            </label>
                        </Link>
                    </li>
                    <li>
                        <Link to={"contact"}>
                            <label onClick={onMenuClick(2)}>
                                <div>
                                    <i className="fa-solid fa-id-card"></i>
                                </div>
                                <span>Đăng ký</span>
                            </label>
                        </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={"info"}>*/}
                    {/*        <label onClick={onMenuClick(3)}>*/}
                    {/*            <div>*/}
                    {/*                <i className="fa-solid fa-square-info"></i>*/}
                    {/*            </div>*/}
                    {/*            <span>Thông tin</span>*/}
                    {/*        </label>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                </ul>
            </div>
        </div>
    );
}

export default NavMobile;
