import React from 'react';
import {motion} from "framer-motion";
import './index.scss';
import benefit_0 from '../../assets/images/benefit/benefit_0.png';
import benefit_1 from '../../assets/images/benefit/benefit_1.png';
import benefit_2 from '../../assets/images/benefit/benefit_2.png';
import benefit_3 from '../../assets/images/benefit/benefit_3.png';
import benefit_4 from '../../assets/images/benefit/benefit_4.png';
import benefit_5 from '../../assets/images/benefit/benefit_5.png';
import benefit_6 from '../../assets/images/benefit/benefit_6.png';
import benefit_7 from '../../assets/images/benefit/benefit_7.png';

function Benefit(props: any) {

    return (
        <div id={"benefit"} className={"container-fluid benefit-container g-0"}>
            <div className={"container-title text-center my-3"}>
                <h2>Special Benefit</h2>
            </div>

            <div className={"container benefit-value-container"}>
                <div className={"row g-4"}>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: .1}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_7} alt={"Gói hỗ trợ truyền thông doanh nghiệp"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Chính sách ưu đãi chuyên biệt</h5>
                        <p className={"benefit-content"}>Với lãi suất cho vay chuyên biệt cho DN nữ, miễn phí trả nợ trước hạn; hạn mức thấu chi không TSBĐ lên tới 5 tỷ đồng và tỷ lệ cấp tín dụng /giá trị TSBĐ cạnh tranh</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: .3}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_1} alt={"Forum đào tạo"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Forum đào tạo</h5>
                        <p className={"benefit-content"}>Forum độc quyền dành cho hội viên, truy cập khóa đào tạo online, cập nhật tin tức về ngành tài chính/ngân hàng và tham gia thảo luận/tư vấn với chuyên gia đầu ngành</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: .6}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_2} alt={"Chỗ ngồi ưu tiên"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Chỗ ngồi ưu tiên</h5>
                        <p className={"benefit-content"}>Được nhận diện và ưu tiên phục vụ tại phòng giao dịch, chi nhánh có KHDN của SeABank</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: .7}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_3} alt={"Tham gia hội nghị cung - cầu thường niên"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Tham gia hội nghị cung - cầu thường niên</h5>
                        <p className={"benefit-content"}>Tham gia và có quầy hàng ưu tiên tại hội nghị cung-cầu kết nối các doanh nghiệp quy mô toàn lãnh thổ Việt Nam</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: 1.2}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_4} alt={"Đào tạo kĩ năng cho CBNV"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Đào tạo kĩ năng cho CBNV</h5>
                        <p className={"benefit-content"}>Tham gia các lớp đào tạo/ tập huấn kĩ năng dành riêng cho CBNV theo nhu cầu của doanh nghiệp</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .3, delay: 1.5}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_5} alt={"Bộ quà tặng cao cấp"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Bộ quà tặng cao cấp</h5>
                        <p className={"benefit-content"}>Nhận bộ quà tặng định danh hội viên cao cấp định kì và vào các dịp đặc biệt như Ngày Quốc tế Phụ nữ, Tết,...</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: 1.8}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_6} alt={"Tham gia private party"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Tham gia private party</h5>
                        <p className={"benefit-content"}>Tham gia tiệc private cao cấp dành riêng cho các hội viên ưu tú</p>
                    </motion.div>
                    <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{ease: "easeInOut", duration: .5, delay: 2.1}} className={"col-12 col-md-3 benefit-column"}>
                        <div className={"benefit-image"}>
                            <img src={benefit_0} alt={"Ưu đãi từ nhãn hàng lớn"} className={"img-fluid w-100"}/>
                        </div>
                        <h5 className={"benefit-title"}>Ưu đãi từ nhãn hàng lớn</h5>
                        <p className={"benefit-content"}>Ưu đãi theo thời kì các sản phẩm/dịch vụ thuộc lĩnh vực làm đẹp, thời trang, du lịch, doanh nghiệp đến từ các nhãn hàng đối tác với SeABank</p>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}

export default Benefit;
