import React from 'react';
import {motion} from "framer-motion";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import './index.scss';
import icon from "../../assets/images/icon.png";

import footer_qrcode_1 from '../../assets/images/footer_qrcode_1.png';
import footer_qrcode_2 from '../../assets/images/footer_qrcode_2.png';
import footer_social_icon_1 from '../../assets/images/footer_social_icon_1.png';
import footer_social_icon_2 from '../../assets/images/footer_social_icon_2.png';
import LogoSeabankWhite from '../../assets/images/logo_seabank_white.svg';
import FacebookLogo from '../../assets/images/facebook.svg';
import Youtubelogo from '../../assets/images/youtube.svg';
import WebLogo from '../../assets/images/www.svg';

function Footer(props: any) {

    return (
        <footer className={"container-fluid footer-container"}>
            <div className={"topBar d-flex flex-row justify-content-center px-2"}>
                <div className={"topBar__left me-2 d-flex flex-column"}>
                    <div className={"top-row"}></div>
                </div>
                <div className="topBar__icon py-3">
                    <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8}} src={icon} alt={"SeaBank"} className={"event-icon"}/>
                </div>
                <div className={"topBar__right ms-2"}>
                    <div className={"top-row"}></div>
                </div>
            </div>
            <div className={"container"}>
                <div className={"row g-5"}>
                    <div className={"col-12 col-md-12 col-lg-4 footer-column"}>
                        <div className={"footer-title text-center"}>
                            <h4>Gia nhập Cộng đồng Doanh nghiệp nữ</h4>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 col-md-6 social-column"}>
                                <div className={"rounded-2"} style={{overflow: "clip"}}>
                                    <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8, delay: .3}} src={footer_qrcode_1} alt={"SeaBank QR"} className={"img-fluid w-100"}/>
                                </div>
                                <div className={"footer-social-card my-3 mx-auto"}>
                                    <div className={"card-body d-flex flex-row"}>
                                        <motion.div initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8, delay: .3}} className={"card-image d-flex flex-column justify-content-center"}>
                                            <LazyLoadImage src={footer_social_icon_1} alt={"SeaBank"} className={"img-fluid"}/>
                                        </motion.div>
                                        <div className={"ms-2 d-flex flex-column justify-content-center"}>
                                            <p>Woman in Business</p>
                                            <span>Cộng đồng nữ doanh nhân Việt</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12 col-md-6 social-column"}>
                                <div className={"rounded-2"} style={{overflow: "clip"}}>
                                    <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8, delay: .3}} src={footer_qrcode_2} alt={"SeaBank QR"} className={"img-fluid w-100"}/>
                                </div>
                                <div className={"footer-social-card my-3 mx-auto"}>
                                    <div className={"card-body d-flex flex-row"}>
                                        <motion.div initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8, delay: .3}} className={"card-image d-flex flex-column justify-content-center"}>
                                            <LazyLoadImage src={footer_social_icon_2} alt={"SeaBank"} className={"img-fluid"}/>
                                        </motion.div>
                                        <div className={"ms-2 d-flex flex-column justify-content-center"}>
                                            <p>Fanpage:</p>
                                            <span>Ngân hàng SeABank</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6 col-lg-2 footer-column"}>
                        <div className={"footer-title"}>
                            <h3 className={"inter-title"}>LIÊN KẾT</h3>
                        </div>
                        <div className={"footer-link d-flex flex-row"}>
                            <ul>
                                <li><a href={"https://www.seabank.com.vn"} className={"link"}>Trang chủ SeABank</a></li>
                                <li><a href={"https://www.seabank.com.vn/doanh-nghiep/ngan-hang-dien-tu.71/dich-vu-internet-banking-seanet.314.html"} className={"link"}>SeANet dành cho KHDN</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className={"col-12 col-md-6 col-lg-3 footer-column"}>
                        <div className={"footer-title"}>
                            <h3 className={"inter-title"}>LIÊN HỆ</h3>
                        </div>
                        <div className={"footer-contact footer-link d-flex flex-row"}>
                            <ul>
                                <li><a href={"tel:1900599952"} className={"link"}>Call center 1: 1900 - 599 - 952</a></li>
                                <li><a href={"tel:02432045952"} className={"link"}>Call center 2: 024 - 32045952</a></li>
                                <li><a href={"mailto:marketing_khdn@seabank.com.vn"} className={"link"}>Email: marketing_khdn@seabank.com.vn</a></li>
                                <li>Địa chỉ: Tòa nhà BRG, 198 Trần Quang Khải, phường Lý Thái Tổ, quận Hoàn Kiếm, Hà Nội</li>
                            </ul>
                        </div>
                    </div>
                    <div className={"col-12 col-md-12 col-lg-3 footer-column"}>
                        <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: .8}} className={"footer-title"}>
                            <LazyLoadImage src={LogoSeabankWhite} className={"img-fluid w-50 w-md-100"}/>
                        </motion.div>
                        <div className={"bankName footer-link d-flex flex-row"}>
                            <a href={"https://www.seabank.com.vn"} className={"link"}>Ngân hàng TMCP Đông Nam Á (SeABank)</a>
                        </div>
                        <div className={"social-links"}>
                            <ul className={"d-flex flex-row"}>
                                <li><a href={"https://www.facebook.com/SeABankOfficialFanpage"} className={"link"}><LazyLoadImage src={FacebookLogo}/></a></li>
                                <li><a href={"https://www.youtube.com/@NganhangSeABankOfficial"} className={"link"}><LazyLoadImage src={Youtubelogo}/></a></li>
                                <li><a href={"https://www.seabank.com.vn/"} className={"link"}><LazyLoadImage src={WebLogo}/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
