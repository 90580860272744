import React from 'react';
import './index.scss';
import EventSlider from "../EventSlider";

function Slider(props:any) {

    return (
        <div id={"slider"} className={"container-fluid slider-container g-0"}>
            <div className={"container-title text-center my-3"}>
                <h2>Sự kiện nổi bật</h2>
            </div>
            <EventSlider/>
        </div>
    );
}

export default Slider;
