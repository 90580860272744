import React from 'react';
import {motion} from "framer-motion";
import './index.scss';
import {Link} from "react-scroll";

import icon from '../../assets/images/icon.png';
import logo from '../../assets/images/logo.png';


function Banner(props:any) {

    return (
        <div className={"container-fluid banner-container g-0"}>
            <div className={"topBar d-flex flex-row justify-content-center px-2"}>
                <div className={"topBar__logo me-2 d-flex flex-column"}>
                    <div className={"top-row d-flex flex-row justify-content-center align-items-center"}>
                        <a href={"https://www.seabank.com.vn/"}>
                            <motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: .8}} src={logo} alt={"SeaBank"} className={"logo img-fluid"}/>
                        </a>
                    </div>
                    <div></div>
                </div>
                <div className="topBar__icon py-3">
                    <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8}} src={icon} alt={"SeaBank"} className={"event-icon"}/>
                </div>
                <div className={"topBar__search ms-2"}>
                    <div className={"top-row"}></div>
                </div>
            </div>

            <nav className={"navbar navbar-expand-md navbar-dark mx-auto d-none d-md-block"}>
                <button className={"navbar-toggler"} type={"button"} data-toggle={"collapse"} data-target={"#navbarSupportedContent"} aria-controls={"navbarSupportedContent"} aria-expanded={"false"} aria-label={"Toggle navigation"}>
                    <span className={"navbar-toggler-icon"}></span>
                </button>
                <div className={"collapse navbar-collapse"} id={"navbarSupportedContent"}>
                    <ul className={"navbar-nav mx-auto"}>
                        <li className={"nav-item active"}>
                            <Link className={"nav-link link"} to={"benefit"} spy={true} smooth={true}>QUYỀN LỢI CHUYÊN BIỆT</Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link className={"nav-link link"} to={"slider"} spy={true} smooth={true}>HOẠT ĐỘNG & SỰ KIỆN</Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link className={"nav-link link"} to={"contact"} spy={true} smooth={true}>ĐĂNG KÝ HỘI VIÊN</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className={"video_area mt-5"}>
                <div className={"embed-responsive"}>
                    <div id={"player1"}></div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
